"use client";
import React from "react";
import Header from "./rewind/Header";
import { useRouter } from "next/navigation";
import { supportUrl } from "@/lib/support";
import Link from "next/link";

const AppRouterErrorComponent = ({
  refresh,
  error,
}: {
  refresh?: () => void;
  error: string;
}) => {
  const router = useRouter();
  return (
    <>
      <div id="menu"></div>
      <Header className="px-6 sm:hidden" />
      <main className="flex min-h-[100svh] w-full flex-col items-center justify-center gap-4 px-2 text-white">
        <p className="text-yellow-100 sm:text-lg">There was a problem</p>

        <h1 className="text-center text-2xl font-bold sm:text-[2.5rem]">
          {error || "Unknown error"}
        </h1>
        <p className="text-center text-gray-500 sm:text-lg">
          Please try again later or contact Setmixer support if the problem
          persists.
        </p>
        <div className="mt-8 flex flex-col gap-2 space-x-0 sm:flex-row sm:space-x-2">
          <button
            onClick={() => {
              if (refresh) {
                refresh();
              } else {
                window.location.reload();
              }
            }}
            className="rounded-sm bg-yellow-100 px-12 py-3 text-black duration-200 hover:bg-gray-300"
          >
            Try again
          </button>
          <button
            onClick={() => router.back()}
            className="rounded-sm border-[1px] border-yellow-100 px-12 py-3 duration-200 hover:border-gray-300 hover:bg-gray-300 hover:text-black"
          >
            Go back
          </button>

          <Link
            href={supportUrl}
            target="support"
            className="rounded-sm border-[1px] border-yellow-100 px-12 py-3 duration-200 hover:border-gray-300 hover:bg-gray-300 hover:text-black"
          >
            Contact support
          </Link>
        </div>
      </main>
    </>
  );
};

export default AppRouterErrorComponent;
