"use client";
import AppRouterErrorComponent from "@/components/AppRouterErrorComponent";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return <AppRouterErrorComponent refresh={reset} error={error.message} />;
}
